// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat-palette($mat-indigo);
$frontend-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat-light-theme((
  color: (
    primary: $frontend-primary,
    accent: $frontend-accent,
    warn: $frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($frontend-theme);

html, body {
  height: 100%;
}

table {
  width: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.login-container {
  background-image: url(/assets/images/bg-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  height: 100%;
}

.example-spacer {
  flex: 1 1 auto;
}

.main-container {
  height: 100%;
}

mat-drawer-content {
  flex-direction: column;
  align-items: flex-start;
}

.custom-toolbar {
  height: 45px;
}

.table-loading-data {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-layout-body {
  padding: 24px;
}
